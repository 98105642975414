import React from 'react'
import styles from './bredkrumRender.module.css'

import { Link } from 'react-router-dom'

import { TextField } from '@rmwc/textfield'
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

import EditIcon from '@material-ui/icons/Edit';

import { Button } from '@rmwc/button'
import '@material/button/dist/mdc.button.css';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


const bredkrumRender = ({bredkrums}) => {

    return Object.keys(bredkrums).map(function(obj, i) {
        return (    
                <div className={styles.bredkrumRenderContainer} key={obj}>
                    <div className={styles.rowOne}>
                        <Link to={{
                            pathname: "/bredkrumEdit",
                            bredkrumInfo: {
                                bredkrumCode: bredkrums[obj].id,
                                itemName: bredkrums[obj].itemName,
                                itemDescription: bredkrums[obj].itemDescription,
                                contactNumber: bredkrums[obj].contactNumber,
                                ownerMessage: bredkrums[obj].ownerMessage
                            }
                            }}>
                            <Button raised icon={<EditIcon />} />
                        </Link>
                    </div>
                    <div className={styles.infoFields}>
                        <TextField
                            className={styles.infoMargin} 
                            outlined
                            disabled
                            label="item name" 
                            defaultValue={bredkrums[obj].itemName}
                            maxLength={25}
                        />
                        <TextField
                            className={styles.infoMargin}
                            outlined
                            disabled
                            defaultValue={bredkrums[obj].itemDescription}
                            label="item description"
                            maxLength={100}
                        /> 
                        <PhoneInput
                            className={styles.infoMargin}
                            name="finderPhoneNumberInput"
                            country="US"
                            disabled
                            limitMaxLength={true}
                            value={bredkrums[obj].contactNumber}
                            onChange={(event) => console.log('')}
                        />
                        <TextField
                            className={styles.infoMargin}
                            outlined
                            disabled
                            defaultValue={bredkrums[obj].ownerMessage}
                            label="message to finder"
                            maxLength={150}
                        />
                    </div>
                </div>
        )
    })
}


export default bredkrumRender
