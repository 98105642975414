import React from 'react'
import { AuthUserContext } from '../../Session'

import UserInfoContainer from './userInfoContainer'
import { LinearProgress } from '@material-ui/core';

const home = (props) => {

    return (
        <AuthUserContext.Consumer>
            {({authUser, userInfo}) => {
                if (authUser && userInfo !== null) {
                   return <UserInfoContainer authUser={authUser} userInfo={userInfo}/>
                } else {
                    return <LinearProgress />
                }
            }}
        </AuthUserContext.Consumer>
    )
} 

export default home 
