import React from 'react'
import styles from './home.module.css'
import SignOutButton from '../SignOut/index'
import { Avatar } from '@rmwc/avatar'
import '@rmwc/avatar/avatar.css';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

function UserInfoContainer({authUser, userInfo}) {

    return (
        <div className={styles.homeContainer}>
            <div className={styles.infoBar}>
                <h2>account information</h2>
                <SignOutButton />  
            </div>
            <div className={styles.homeContentContainer}>
                <div className={styles.avatar}>
                    {authUser.photo 
                        ? <Avatar src={authUser.photo} style={{width: '6rem', height: '6rem'}} /> 
                        : <AccountCircleIcon style={{width: '6rem', height: '6rem'}}/>
                    }
                </div>
                <div className={styles.profileInfo}>
                    <h4>name:</h4>
                    <p>{userInfo.displayName}</p>
                    <h4>email:</h4>
                    <p>{userInfo.email}</p>
                </div>
            </div>
        </div> 
    )
}

export default UserInfoContainer