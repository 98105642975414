import React from 'react'

import { BottomNavigation } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
      background: '#D2D7D3',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      height: '56px',
    }
  });
  
  export default function SimpleBottomNavigation() {
    const classes = useStyles();
  
    return (
      <BottomNavigation
        className={classes.root}
      >
      </BottomNavigation>
    );
  }
