import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import axios from  'axios'

import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import { FinderFormContext } from '../../Session/withFinderFormContext'
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles({
    root: {
      background: '#D2D7D3',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      height: '56px'
    }
});

const SimpleBottomNavigation = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [readyToSend, setReadyToSend] = useState(false)

    const finder = useContext(FinderFormContext)
    const { finderHasItSwitch, finderLocationInformation, finderMessageToOwner, finderNumber, bredkrumInfo } = useContext(FinderFormContext)

    useEffect(() => {
        if ( finderLocationInformation.message.length !== 0 && (!finderHasItSwitch.hasIt) ) {
            setReadyToSend(true)
        } else if (finderLocationInformation.message.length !== 0 && finderHasItSwitch.hasIt && finderNumber.length === 12) {
          setReadyToSend(true)
        } else {
            setReadyToSend(false)
        }
    },[finder])

    async function sendMessageToOwner(event) {

      let messageInfo = {
        bredkrumInfo: bredkrumInfo,
        finderLocationMessage: finderLocationInformation.message,
        finderMessageToOwner: finderMessageToOwner.message,
        finderNumber: finderNumber
      }

      let url = 'https://us-central1-bredkrumv1.cloudfunctions.net/sendOwnerText'

      await axios.post(
        url,
        { messageInfo },
        { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : 'Origin' } }
      )
    }

    function redirectThankYou() {
      return props.history.push('/thankyou')
    }


    return (
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              // changes the button color when clicked
                setValue(newValue);
            }}
            onClick={async (event) => {
              // await sendMessageToOwner(event)
              // removed the await for UX.  Would be nice to have a confirmation instead
              sendMessageToOwner(event)
              redirectThankYou()
            }}
            showLabels
            className={classes.root}
          > 
              {readyToSend ? <BottomNavigationAction value="sendMessage" label="sendMessage" icon={<SendIcon />} /> : <h4>Please fill out required fields</h4>}
          </BottomNavigation>
    );
}

export default withRouter(SimpleBottomNavigation)
