import React from 'react'

import { TextField } from '@rmwc/textfield'
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

import { FinderFormContext } from '../../Session/withFinderFormContext'

const locationTextField = () => {

    return (
        <FinderFormContext.Consumer>
            {(value) => (
                    <TextField
                        outlined
                        name="finderLocationInformation"
                        label="I found it at ..."
                        required={true}
                        value={value.finderLocationInformation.message}
                        onChange={(event) => value.updateFinderLocationInformation(event.target.value)}
                    />
            )}
        </FinderFormContext.Consumer>
    )
}

export default locationTextField
