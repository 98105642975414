import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

import styles from './SignIn.module.css'

import { FirebaseContext } from '../../Firebase'

const uiConfig = {
  signInFlow: 'redirect',
  signInSuccessUrl: '/home',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ]
};

const SignInScreen = () => (
      <div className={styles.SignIn}>
        <FirebaseContext.Consumer>
            {firebase => <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth}/>}
        </FirebaseContext.Consumer>
      </div>
)

export default SignInScreen
