import React from 'react'
import styles from './ThankYou.module.css'
import { Link } from 'react-router-dom'

import { Button } from '@rmwc/button'
import '@material/button/dist/mdc.button.css';


const ThankYou = () => {

    return (
         <div className={styles.thankyouContainer}>
            <h1>thank you for your help!</h1>
            <h3>your message was sent to the owner</h3>
            
            <p>want to join? register below</p>
            <Link to="/signin"><Button label="signin / register" raised /></Link>
        </div>
    )
}

export default ThankYou