import React, {useContext, useEffect} from 'react'
import styles from './foundIt.module.css'

import OwnerMessage from '../../components/JumboTron/ownerMessage'
import FinderMessage from '../../components/JumboTron/finderMessage'

import { FinderFormContext } from '../../Session/withFinderFormContext'

function FoundIt({bredkrumInfo}) {

    const {updateBredkrumInfo} = useContext(FinderFormContext)

    useEffect(() => {
        updateBredkrumInfo(bredkrumInfo)
    },[bredkrumInfo])

    return (
        <div className={styles.founditContainer}>
            <OwnerMessage bredkrumInfo={bredkrumInfo}/>
            <FinderMessage bredkrumInfo={bredkrumInfo}/>
        </div>
    )
}

export default FoundIt


