import React from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import styles from '../Cards/finderMessageCard.module.css'
import { FinderFormConsumer } from '../../Session/withFinderFormContext'

const phoneNumberInput = () => {
    
    return (
      <FinderFormConsumer>
        {(value) => (
          <PhoneInput
            className={styles.phoneInput}
            name="finderPhoneNumberInput"
            autoComplete="on"
            country="US"
            required={true}
            limitMaxLength={true}
            value={value.finderNumber}
            onChange={(event) => {value.updateFinderNumber(event)}}
          />
        )}
        </FinderFormConsumer>
    )
}

export default phoneNumberInput