import React from 'react';
import { withRouter } from 'react-router'
import { withFirebase } from '../../Firebase'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Button } from '@rmwc/button'
import '@material/button/dist/mdc.button.css';

const SignOutButton = ({firebase, history }) => {

    const signOutActions = () => {
        firebase.doSignOut()
        history.push('/')
    }

    return (
        <Button raised trailingIcon={<ExitToAppIcon />}  label="signout" onClick={() => signOutActions()} />
    )
}

export default withRouter(withFirebase(SignOutButton))
