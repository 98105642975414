import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import axios from  'axios'
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import { BredkrumRegistrationContext } from '../../Session/withBredkrumRegistration'
import { AuthUserContext } from '../../Session'

const useStyles = makeStyles({
    root: {
      background: '#D2D7D3',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      height: '56px',
    }
});

const BredkrumRegistrationBottomNavigator = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [readyToRegister, setReadyToRegister] = useState(false)

    const bredkrumRegistration = useContext(BredkrumRegistrationContext)  
    const {contactNumber, itemName, itemDescription, ownerMessage } = useContext(BredkrumRegistrationContext)

    const {authUser} = useContext(AuthUserContext)

    let bredkrumCode = JSON.parse(localStorage.getItem('bredkrumCode'))

    useEffect(() => {
        if ( itemName.length !== 0 && itemDescription.length !== 0 && ownerMessage.length !== 0 && contactNumber.length === 12) {
            setReadyToRegister(true)
        } else {
            setReadyToRegister(false)
        }
    },[bredkrumRegistration])

    async function sendRegisterBredkrumToOwner(event) {
      let url = 'https://us-central1-bredkrumv1.cloudfunctions.net/registerBredkrum'

      let registrationInfo = {
        uid: authUser.uid,
        bredkrumCode,
        itemName,
        itemDescription,
        ownerMessage,
        contactNumber
      }

      await axios.post(
        url,
        { registrationInfo },
        { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : 'Origin' } }
      )
    }

    const removeLocalStorage = () => {
      localStorage.removeItem('bredkrumCode')
    }

    function redirectBredkrum() {
      return (
        props.history.push('/home'),
        window.location.reload(true)
      )
    }

    return (
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              // changes the button color when clicked
                setValue(newValue);
            }}
            // set the new item, send it back, redirect to home, refresh the home screen
            onClick={async (event) => {
              await sendRegisterBredkrumToOwner(event)
              removeLocalStorage()
              redirectBredkrum()
            } }
            showLabels
            className={classes.root}
          > 
              {readyToRegister ? <BottomNavigationAction value="addBredkrum" label="add bredkrum" icon={<AddIcon />} /> : <h4>Please fill out required fields</h4>}
          </BottomNavigation>
    );
}

export default withRouter(BredkrumRegistrationBottomNavigator)