import React from 'react'
import styles from './bredkrumPage.module.css'

import { AuthUserContext } from '../../Session'

import BredkrumRender from '../../components/Cards/bredkrumRender.js'
import { LinearProgress } from '@material-ui/core';

const bredkrumPage = () => {

    return (
        <div className={styles.bredkrumPageContainer}>

            <h2>registered items</h2>
            <AuthUserContext.Consumer>
                {({authUser, userInfo}) => {
                    if (authUser && userInfo !== null) {
                        return <BredkrumRender bredkrums={userInfo.bredkrums}/>
                    } else {
                        return <LinearProgress />
                    }
                }}
            </AuthUserContext.Consumer>

        </div>
    )
}

export default bredkrumPage