import React from 'react'
import { withRouter } from 'react-router'

import BottomNavigation from './bottomNavigation'
import BottomNavigationFoundIt from './bottomNavigationFoundIt'
import BottomNavigationEmpty from './bottomNavigationEmpty'
import BredkrumRegistrationBottomNavigator from './bottomNavigationBredkrumRegistration'

const bottomNavigator = (props) => {
    let initalPath = props.location.pathname
    let pathStrip = new RegExp("^.*\/(.*)\/.*|^.*\/(.*)")
    let regexPath = pathStrip.exec(initalPath)

    let routePath = regexPath[1] === undefined ? regexPath[2] : regexPath[1]

        switch (routePath) {
            case 'home':
                return <BottomNavigation />
            case 'bredkrums':
                return <BottomNavigation />
            case 'info':
                return <BottomNavigation />
            case "foundit":
                return <BottomNavigationFoundIt />
            case "bredkrumRegistration":
                return <BredkrumRegistrationBottomNavigator />
            default:
                return <BottomNavigationEmpty />
        }
}

export default withRouter(bottomNavigator)
