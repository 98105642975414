import React from 'react'
import styles from './bredkrumRegistration.module.css'

import { AuthUserContext } from '../../Session'
import { LinearProgress } from '@material-ui/core';

import { Link } from 'react-router-dom'
import { Button } from '@rmwc/button'

const bredkrumRegistration = ({props}) => {

    return (
        <AuthUserContext.Consumer>
            {({authUser, userInfo}) => {
                if (authUser && userInfo === null) {
                    return <LinearProgress />
                } else if (authUser && userInfo !== null) {
                    return (
                        <div className={styles.bredkrumPageContainer}>
                            <h3>It looks like you are a current user</h3>
                            <Link to="/bredkrumRegistration"><Button label="register new bredkrum" raised /></Link>
                        </div>
                    )       
                } else {
                    return (
                        <div className={styles.bredkrumPageContainer}>
                            <h1>please login/register to setup bredkrum</h1>
                            <Link to="/bredkrumsignin"><Button label="signin / register" raised /></Link>
                        </div>
                    ) 
                }
            }}
        </AuthUserContext.Consumer>
    )
}

export default bredkrumRegistration