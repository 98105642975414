import React from 'react'

const info = () => {

    return (
        <div>
            <h1>Info coming soon...</h1>
        </div>
    )
}

export default info