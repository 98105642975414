import React from 'react'

import styles from './header.module.css'
import logo from '../../Assets/Images/bredkrum.png'

const header = () => {

    return (
        <div className={styles.headerLogo}>
            <img src={logo} alt="bredkrum" />
        </div>
    )
}

export default header

