import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import styles from './SignIn.module.css'

import { FirebaseContext } from '../../Firebase'

const BredkrumSignInScreen = () => {
  let bredkrumCode = JSON.parse(localStorage.getItem('bredkrumCode'))

  const uiConfig = {
    signInFlow: 'redirect',
    signInSuccessUrl: `/bredkrumRegistration/${bredkrumCode}`,
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ]
  };

  return (
      <div className={styles.SignIn}>
        <FirebaseContext.Consumer>
            {firebase => <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth}/>}
        </FirebaseContext.Consumer>
      </div>
    )
}

export default BredkrumSignInScreen
