import React from 'react'
import styles from './bredkrumRegistrationLoggedIn.module.css'
import { AuthUserContext } from '../../Session'

import BredkrumRegistration from '../../components/Cards/bredkrumRegistration'

const BredkrumRegistrationLoggedIn = () => {

    return (
        <AuthUserContext.Consumer>
            {({authUser, userInfo}) => (
                <div className={styles.bredkrumPageContainer}>
                    <h2>register bredkrum</h2>
                    <BredkrumRegistration authUser={authUser} userInfo={userInfo}/>
                </div>
            )}
        </AuthUserContext.Consumer>
    )
}

export default BredkrumRegistrationLoggedIn