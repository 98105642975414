import React from 'react'
import { Link } from 'react-router-dom'

import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from '@material-ui/icons/Home';
import CropFreeIcon from '@material-ui/icons/CropFree';
import HelpIcon from '@material-ui/icons/Help';

// use logic to diable the buttons with login
// disable buttons until authenticated

const useStyles = makeStyles({
    root: {
      // maxwidth: 300,
      background: '#D2D7D3',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      height: '56px',
    }
  });
  
  export default function SimpleBottomNavigation() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
  
    return (
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
            setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction component={Link} to="/home" value="home" label="home" icon={<HomeIcon />}  />
        <BottomNavigationAction component={Link} to="/bredkrums" value="bredkrums" label="bredkrums" icon={<CropFreeIcon />} />
        <BottomNavigationAction component={Link} to="/info" value="info" label="info" icon={<HelpIcon />} />
      </BottomNavigation>
    );
  }
  