import React from 'react';
import {BrowserRouter as Router, Route } from 'react-router-dom'

import {FinderFormProvider} from '../src/Session/withFinderFormContext'
import {BredkrumRegistrationProvider} from '../src/Session/withBredkrumRegistration'

import styles from './App.module.css'
import Header from './Pages/Header/header'
import Home from './Pages/Home/home'
import BredkrumPage from './Pages/BredkrumPage/bredkrumPage'
import Info from '../src/Pages/info'

import Landing from '../src/Pages/landing'
import ThankYou from '../src/Pages/ThankYou/index'
import SignInScreen from '../src/Pages/SignIn/index'
import BredkrumSignInScreen from '../src/Pages/bredkrumRegistrationSignIn/index'
import BredkrumEditPage from '../src/Pages/BredkrumEdit/index'
import withAuthentication from '../src/Session/withAuthentication'

import FoundItLogic from '../src/Pages/FoundIt/foundItLogic'
import BottomNavigatorLogic from '../src/components/BottomNavigation/bottomNavigatorLogic'
import BredkrumRegistrationLoggedIn from '../src/Pages/bredkrumRegistrationLoggedIn/index'

function App() {

  return (
      <Router>
        <FinderFormProvider>
        <BredkrumRegistrationProvider>
        <div className={styles.App}>
          <Header />
          <div className={styles.siteContent}>
            <Route exact path="/" component={Landing} />
            <Route path="/signin" component={SignInScreen} />
            <Route path="/bredkrumsignin" component={BredkrumSignInScreen} />
            <Route path="/bredkrumRegistration" component={BredkrumRegistrationLoggedIn} />
            <Route path="/bredkrumEdit" component={BredkrumEditPage} />
            <Route path="/bredkrums" component={BredkrumPage} />
            <Route path="/home" component={Home} />
            <Route path="/info" component={Info} />
            <Route path="/thankyou" component={ThankYou} />
            <Route 
              path="/foundit/:bredkrumCode"
              render={(props) => <FoundItLogic bredkrumCode={props.match.params.bredkrumCode} />}
            />
          </div>
          <BottomNavigatorLogic className={styles.footer}/>
        </div>
        </BredkrumRegistrationProvider>
        </FinderFormProvider>
      </Router>
    ); 
}

export default withAuthentication(App)

