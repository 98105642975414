import React, {useEffect, useState} from 'react'
import { withRouter } from 'react-router'
import FoundIt from './foundIt'
import BredkrumRegistration from '../BredkrumRegistration/bredkrumRegistration'
import { LinearProgress } from '@material-ui/core';

const FoundItLogic = (props) => {
    const [bredkrumInfo, setBredkrumInfo] = useState({});
    const [inUse, setInUse] = useState(null)
    const [ loading, setLoading ] = useState(true)

    let bredkrumCode = props.bredkrumCode
    let url = `https://us-central1-bredkrumv1.cloudfunctions.net/lookUpBredkrum?code=${bredkrumCode}`

    async function fetchData() {

        const res = await fetch(url);
            res.json()
                .then(res => {
                    setBredkrumInfo(res[0])
                    setInUse(res[0].inUse)
                    setLoading(false)
                })
                .catch(err => console.log(err));
    }

    useEffect(() => {
        setLoading(true)
        fetchData()

    }, [inUse])

    function loadingRender() {
        if (loading) {
            return <LinearProgress />
        } else if (inUse) {
            return <FoundIt bredkrumInfo={bredkrumInfo} /> 
        } else if (inUse ===  false) {
            localStorage.setItem('bredkrumCode', JSON.stringify(props.bredkrumCode))
            return <BredkrumRegistration props={bredkrumInfo} />
        }
    }

    return (
        <React.Fragment>
            {loadingRender()}
        </React.Fragment>
    )
}

export default withRouter(FoundItLogic)