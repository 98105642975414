import React from 'react'
import styles from './finderHasIt.module.css'

import { Switch } from '@rmwc/switch'
import '@material/switch/dist/mdc.switch.css';
import '@material/form-field/dist/mdc.form-field.css';

import FinderMessageCard from '../Cards/finderMessageCard'
import { FinderFormConsumer } from '../../Session/withFinderFormContext'


function FinderHasItSwitch() {

    return (
        <FinderFormConsumer>
            {(value) => (
                <React.Fragment>
                    <div className={styles.switchContainer}>
                        <p>I left it there</p>
                        <Switch 
                            value="hasIt"
                            checked={value.finderHasItSwitch.hasIt}
                            onChange={() => value.updateFinderHasItSwitch()}
                        />
                        <p>I have it</p>
                    </div>
                    <div className={styles.switchCard}>
                        {value.finderHasItSwitch.hasIt ? <FinderMessageCard open={true} /> : null}
                    </div>
                </React.Fragment>
            )}

        </FinderFormConsumer>
    )
}

export default FinderHasItSwitch

