import React from 'react'
import { Jumbotron } from 'reactstrap';
import styles from './ownerMessage.module.css'

const ownerMessage = ({bredkrumInfo}) => {

    return (
        <React.Fragment>
            <Jumbotron className={styles.jumbotron}>
                <div className={styles.title}>
                    <h2>message from owner</h2>
                </div>
                <div className={styles.message}>
                    <p>{bredkrumInfo.ownerInfo.ownerMessage}</p>
                </div>
            </Jumbotron>
        </React.Fragment>
    )
}

export default ownerMessage


