import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@rmwc/button'
import '@material/button/dist/mdc.button.css';

import styles from './landing.module.css'

const landing = ( ) => {

    return (
        <div className={styles.landingContainer}>
            <h1>welcome</h1>
            <p>please login</p>
            <Link to="/signin"><Button label="signin / register" raised /></Link>
        </div>
    )
}

export default landing
