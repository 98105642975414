
import React, { createContext, Component } from 'react'

export const FinderFormContext = createContext(null)

export class FinderFormProvider extends Component {

    // // inital finder inputs
    updateFinderLocationInformation = newMessage => {
        this.setState(previousState => ({
            ...previousState,
            finderLocationInformation: {
                ...previousState.finderLocationInformation,
                message: newMessage
            }
        }))
    }

    updateFinderMessageToOwner = newMessage => {
        this.setState(previousState => ({
            ...previousState,
            finderMessageToOwner: {
                ...previousState.finderMessageToOwner,
                message: newMessage
            }
        }))
    }

    updateFinderHasItSwitch = () => {
        this.setState(previousState => ({
            ...previousState,
            finderHasItSwitch: {
                ...previousState.finderHasItSwitch,
                hasIt: !previousState.finderHasItSwitch.hasIt
            }
        }))
    }

    updateFinderNumber = numberInput => {
        this.setState(previousState => ({
            ...previousState,
            finderNumber: numberInput
        }))
    }

    updateBredkrumInfo = (bredkrumInfo) => {
        this.setState(previousState => ({
            ...previousState,
            bredkrumInfo: bredkrumInfo
        }))
    }
    
    state = {
        finderLocationInformation: {
            message: ''
        },
        finderMessageToOwner: {
            message: ''
        },
        finderHasItSwitch: {
            disabled: true,
            hasIt: false
        } ,
        finderNumber: '+1',
        bredkrumInfo: {},
        updateFinderLocationInformation: this.updateFinderLocationInformation,
        updateFinderMessageToOwner: this.updateFinderMessageToOwner,
        updateFinderHasItSwitch: this.updateFinderHasItSwitch,
        updateFinderNumber: this.updateFinderNumber,
        updateBredkrumInfo: this.updateBredkrumInfo
    }

    render() {
        return(
            <FinderFormContext.Provider value={this.state}>
                {this.props.children}
            </FinderFormContext.Provider> 
        )
    }
}

export const FinderFormConsumer = FinderFormContext.Consumer


