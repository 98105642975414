import React from 'react'
import { CardBody, Card } from 'reactstrap';

import styles from './finderMessageCard.module.css'
import PhoneNumberInput from '../TextFields/phoneNumberInput'

const FinderMessageCard = (props) => {

    return (
        <Card className={styles.cardContainer}>
            <CardBody className={styles.cardBody}>
                <h3>My Number is:</h3>
                <PhoneNumberInput />
            </CardBody>
        </Card>
    )
}

export default FinderMessageCard