
import React, { createContext, Component } from 'react'

export const BredkrumRegistrationContext = createContext(null)

export class BredkrumRegistrationProvider extends Component {

    updateItemName = newName => {
        this.setState(previousState => ({
            ...previousState,
            itemName: newName
        }))
    }

    updateItemDescription = newItemDescription => {
        this.setState(previousState => ({
            ...previousState,
            itemDescription: newItemDescription
        }))
    }

    updateContactNumber = newContactNumber => {
        this.setState(previousState => ({
            ...previousState,
            contactNumber: newContactNumber
        }))
    }

    updateOwnerMessage = newOwnerMessage => {
        this.setState(previousState => ({
            ...previousState,
            ownerMessage: newOwnerMessage
        }))
    }
    
    state = {
        itemName: '',
        itemDescription: '',
        contactNumber: '+1',
        ownerMessage: '',
        readyToRegister: false,
        updateItemName: this.updateItemName,
        updateItemDescription: this.updateItemDescription,
        updateContactNumber: this.updateContactNumber,
        updateOwnerMessage: this.updateOwnerMessage
    }

    render() {
        return(
            <BredkrumRegistrationContext.Provider value={this.state}>
                {this.props.children}
            </BredkrumRegistrationContext.Provider> 
        )
    }
}

