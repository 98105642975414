import React, { useState } from 'react'
import AuthUserContext from './context'

import { withFirebase } from '../Firebase'

const withAuthentication = Component => {
    const useWithAuthentication = (props) => {

        const [ authState, setAuthState ] = useState(AuthUserContext)
        // authUser: JSON.parse(localStorage.getItem('authUser'))

        React.useEffect(() => {
        
            const unsubscribe = props.firebase.auth.onAuthStateChanged(
                async authUser => {
                    if (authUser) {
                        localStorage.setItem('authUser', JSON.stringify(authUser))
                        let userInfo = props.firebase.doGetUserInfo(authUser)

                        setAuthState({
                            authUser: {
                                uid: authUser.uid,
                                displayName: authUser.displayName,
                                email: authUser.email,
                                emailVerified: authUser.emailVerified,
                                photo: authUser.photoURL
                            },
                            userInfo: await userInfo
                        })

                    } else {
                        localStorage.removeItem('authUser')
                        setAuthState({
                            authUser: null,
                            userInfo: null
                        })
                    }
            })
            return () => unsubscribe()
        }, [AuthUserContext])

        return (
            <AuthUserContext.Provider value={authState}>
                <Component {...props} />
            </AuthUserContext.Provider>
        )
    }
    return withFirebase(useWithAuthentication)
}

export default withAuthentication
