import React, {useState, useContext} from 'react'
import styles from './index.module.css'
import { withRouter } from 'react-router-dom'

import axios from  'axios'
import { TextField } from '@rmwc/textfield'
import { Button } from '@rmwc/button'
import { CircularProgress } from '@rmwc/circular-progress'
import '@material/button/dist/mdc.button.css';
import '@rmwc/circular-progress/circular-progress.css';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import { AuthUserContext } from '../../Session/index'

const BredkrumEditPage = (props) => {
    let bredkrumInfo = props.location.bredkrumInfo
    const { authUser } = useContext(AuthUserContext)

    const [ itemName, setItemName ] = useState(bredkrumInfo.itemName)
    const [ itemDescription, setItemDescription ] = useState(bredkrumInfo.itemDescription)
    const [ contactNumber, setContactNumber ] = useState(bredkrumInfo.contactNumber)
    const [ ownerMessage, setOwnerMessage ] = useState(bredkrumInfo.ownerMessage)
    const [ loading, setLoading ] = useState(false)
  
    async function updateBredkrum(){
        let url = 'https://us-central1-bredkrumv1.cloudfunctions.net/updateBredkrum'

        let updateInfo = {
            uid: authUser.uid,
            bredkrumCode: bredkrumInfo.bredkrumCode,
            itemName,
            itemDescription,
            contactNumber,
            ownerMessage
        }

        await axios.post(
            url,
            { updateInfo },
            { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : 'Origin' } }
          )
    }

    async function redirectHome() {
        return (
            await props.history.push('/bredkrums'),
            window.location.reload(true)
        )
    }

    return (
        <div className={styles.bredkrumPageContainer}>
            <h2>edit bredkrum</h2>
            <div className={styles.bredkrumRenderContainer}>
                <div className={styles.rowOne}>
                        <Button raised icon={<CancelIcon />} onClick={() => props.history.push('/bredkrums')}/>
                        <Button 
                            label={loading ? "saving" : ""} 
                            raised 
                            icon={loading ? <CircularProgress /> : <SaveIcon />} 
                            onClick={async (event) => {
                                setLoading(true)
                                await updateBredkrum()
                                await redirectHome()
                            }}
                        />
                </div>
                <div className={styles.infoFields}>
                    <TextField 
                        className={styles.infoMargin} 
                        outlined 
                        label="item name" 
                        value={itemName}
                        onChange={(event) => setItemName(event.target.value)}
                        maxLength={25}
                    />
                    <TextField 
                        className={styles.infoMargin}
                        outlined
                        value={itemDescription}
                        onChange={(event) => setItemDescription(event.target.value)}
                        label="item description"
                        maxLength={100}
                    /> 
                    <PhoneInput 
                        className={styles.infoMargin}
                        name="finderPhoneNumberInput"
                        autoComplete="on"
                        country="US"
                        limitMaxLength={true}
                        value={contactNumber}
                        onChange={(value) => setContactNumber(value)}
                    />
                    <TextField 
                        className={styles.infoMargin}
                        outlined
                        value={ownerMessage}
                        onChange={(event) => setOwnerMessage(event.target.value)}
                        label="message to finder"
                        maxLength={150}
                    />
                </div>
            </div>
        </div>
    )
}

export default withRouter(BredkrumEditPage)