import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.firestore()
  }

  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);
  
  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  doLog = () => console.log("hellololol")

  async doGetUserInfo(authUser) {
    const userRef = this.db.collection(`users`).doc(authUser.uid)
    let userInfo = []

    return await userRef.get()
      .then(doc => {
        if (doc.exists) {
          userInfo.push({
            id: doc.id,
            displayName: doc.data().displayName,
            email: doc.data().email,
            emailVerified: doc.data().emailVerified,
            bredkrums: doc.data().bredkrums
            })
          
          return userInfo[0]

        } else {
          return this.doRegisterNewUser(authUser)
        }
      })
      .catch(error => {
        console.log("error getting doc", error)
      })
  }

  async doRegisterNewUser(authUser) {
    const usersColRef = this.db.collection(`users`)

    return await usersColRef.doc(authUser.uid)
      .set({
        id: authUser.uid,
        displayName: authUser.displayName,
        email: authUser.email,
        emailVerified: authUser.emailVerified,
        bredkrums: {}
      })
      .then(() => this.doGetUserInfo(authUser))
  }

}

export default Firebase

