import React from 'react'
import styles from './bredkrumRegistration.module.css'

import { TextField } from '@rmwc/textfield'
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { BredkrumRegistrationContext } from '../../Session/withBredkrumRegistration'

const BredkrumRegistration = () => {

    return (
        <BredkrumRegistrationContext.Consumer>
            {(value) => (
                <div className={styles.bredkrumRenderContainer}>
                    <div className={styles.rowOne}>
                    </div>
                    <div className={styles.infoFields}>
                        <TextField 
                            className={styles.infoMargin} 
                            outlined 
                            label="item name" 
                            value={value.itemName}
                            onChange={(event) => value.updateItemName(event.target.value)}
                            maxLength={25}
                        />
                        
                        <TextField 
                            className={styles.infoMargin}
                            outlined
                            value={value.itemDescription}
                            label="item description"
                            maxLength={100}
                            onChange={(event) => value.updateItemDescription(event.target.value)}
                        /> 

                        <PhoneInput 
                            className={styles.infoMargin}
                            name="finderPhoneNumberInput"
                            autoComplete="on"
                            country="US"
                            limitMaxLength={true}
                            value={value.contactNumber}
                            onChange={(event) => value.updateContactNumber(event)}
                        />

                        <TextField 
                            className={styles.infoMargin}
                            outlined
                            value={value.ownerMessage}
                            onChange={(event) => value.updateOwnerMessage(event.target.value)}
                            label="message to finder"
                            maxLength={150}
                        />
                    </div>
                </div>
            )}
        </BredkrumRegistrationContext.Consumer>
    )
} 

export default BredkrumRegistration







  