import React from 'react'
import styles from '../JumboTron/finderMessage.module.css'

import { TextField } from '@rmwc/textfield'
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

import { FinderFormConsumer } from '../../Session/withFinderFormContext'

const finderMessageToOwner = () => {

    return (
        <FinderFormConsumer>
            {(value) => (
                <TextField
                    className={styles.additionalInformation}
                    outlined
                    name="finderLocationInformation"
                    label="message to owner"
                    value={value.finderMessageToOwner.message}
                    onChange={(event) => value.updateFinderMessageToOwner(event.target.value)}
                />
            )}
        </FinderFormConsumer>
    )
}

export default finderMessageToOwner