import React from 'react'
import { Jumbotron } from 'reactstrap';
import styles from './finderMessage.module.css'

import LocationTextfield from '../TextFields/locationTextField'
import FinderMessageToOwner from '../TextFields/finderMessageToOwner'
import FinderHasItSwitch from '../Switch/finderHasIt'

const FinderMessage = ({bredkrumInfo}) => {

    return (
        <React.Fragment>
            <Jumbotron className={styles.jumbotron}>
                <h2 className={styles.title}>message to owner</h2>
                <div className={styles.finderMessageContainer}>
                    <p>Hello!  I saw your {bredkrumInfo.ownerInfo.itemName}</p>
                    <div className={styles.textFields} >
                        <LocationTextfield />
                        <FinderMessageToOwner />
                    </div>
                    <FinderHasItSwitch />
                </div>
            </Jumbotron>
        </React.Fragment> 
    )
}

export default FinderMessage